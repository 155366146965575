import React from 'react';
import { useTranslation } from 'react-i18next';
import founderImage from '../images/founder.jpeg'; // Replace with your actual image path
import ceoImage from '../images/ceo.png'; // Replace with your actual image path

const imageStyle = {
    height: '10rem',
    width: 'auto',
    borderRadius: '50%',
    objectFit: 'cover',
    display: 'block',
    margin: '0 auto 1rem auto' // Center horizontally and add bottom margin
};

const QuotesSection = () => {
    const { t } = useTranslation();

    return (
        <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">{t('quotes')}</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">{t('words_from_leaders')}</h2>
                </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8">
                        <div className="text-center bg-white p-6 rounded-lg shadow-lg">
                            <img src={founderImage} alt="Founder" style={imageStyle} />
                            <blockquote className="italic text-lg mb-2">
                                "{t('founder_quote')}"
                            </blockquote>
                            <p className="font-bold text-blue-900">— {t('founder')}</p>
                        </div>

                        <div className="text-center bg-white p-6 rounded-lg shadow-lg">
                            <img src={ceoImage} alt="CEO" style={imageStyle} />
                            <blockquote className="italic text-lg mb-2">
                                "{t('ceo_quote')}"
                            </blockquote>
                            <p className="font-bold text-blue-900">— {t('ceo')}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default QuotesSection;
