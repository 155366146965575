import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const NavLinks = () => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center space-x-4 md:space-x-6">
            <HashLink className="nav-link" smooth to="/#about"     style={{ padding: '10px' }}
            >
                {t('about')}
            </HashLink>
            <HashLink className="nav-link" smooth to="/#services">
                {t('services')}
            </HashLink>
            <HashLink className="nav-link" to="/#portfolio">
                {t('portfolio')}
            </HashLink>
            <HashLink className="nav-link" to="/contact#contact">
                {t('contactUs')}
            </HashLink>
            <HashLink className="cta-link" smooth to="/get-demo#demo">
                {t('demo')}
            </HashLink>
        </div>
    );
};

export default NavLinks;
