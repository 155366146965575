import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/EN/translation.json';
import translationAR from './locales/AR/translation.json';

// Resources for different languages
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

// Function to update direction based on language
const updateDirection = (lng) => {
  document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
};

// Initialize i18next
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ar', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    initImmediate: false,
  });

// Update direction on language change
i18n.on('languageChanged', (lng) => {
  updateDirection(lng);
});

// Ensure initial direction is set
updateDirection(i18n.language);

export default i18n;
