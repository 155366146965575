import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';

const NavBar = () => {
  const { t, i18n } = useTranslation();
  const [top, setTop] = useState(!window.scrollY);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
  }

  function toggleDropdown() {
    setDropdownOpen(!dropdownOpen);
  }

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };

  return (
    <nav className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16 ${!top && 'bg-white shadow-lg'}`} 
        style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
    >
      <div className="flex flex-row justify-between items-center py-2 px-4 md:px-12">
        <div className="flex flex-row justify-center items-center text-center font-semibold">
          <HashLink smooth to="/#hero">
            <h1 className="font-extrabold text-4xl text-blue-900">{t('appName')}</h1>
          </HashLink>
        </div>
        <div className="flex flex-row items-center space-x-6">
          <div className='hidden lg:flex space-x-6'>
            <NavLinks />
          </div>
          <button className="lg:hidden p-2 rounded-lg text-blue-900" onClick={handleClick}>
            <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              {isOpen && (
                <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
              )}
              {!isOpen && (
                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
              )}
            </svg>
          </button>
          <div className={`fixed transition-transform duration-300 ease-in-out flex flex-col items-center left-0 w-full h-auto rounded-md p-6 bg-white lg:hidden shadow-xl top-14 ${isOpen ? "block" : "hidden"}`}>
            <NavLinks />
          </div>
          <div className="relative">
            <button onClick={toggleDropdown} className="px-4 py-2 rounded bg-gray-200 text-gray-800">
              {i18n.language === 'en' ? 'EN' : 'AR'}
            </button>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 bg-white border border-gray-300 shadow-lg rounded">
                <button 
                  onClick={() => handleLanguageChange('en')} 
                  className={`block px-4 py-2 text-gray-800 hover:bg-gray-200 ${i18n.language === 'en' ? 'bg-blue-100' : ''}`}
                >
                  EN
                </button>
                <button 
                  onClick={() => handleLanguageChange('ar')} 
                  className={`block px-4 py-2 text-gray-800 hover:bg-gray-200 ${i18n.language === 'ar' ? 'bg-blue-100' : ''}`}
                >
                  AR
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
